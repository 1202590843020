<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="6"
      >
        <dashboard-manage-user-card />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <dashboard-manage-workflow-card />
      </v-col>
    </v-row>

    <v-row class="match-height">
      <v-col
        cols="12"
        lg="4"
      >
        <v-row class="match-height">
          <v-col
            cols="12"
          >
            <welcome-card height="215px" />
          </v-col>
          <v-col
            cols="12"
          >
            <dashboard-manage-tokopedia-card />
          </v-col>
          <v-col
            cols="12"
          >
            <dashboard-manage-transaction-card />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="8"
      >
        <workflow-admin-chart />
      </v-col>
    </v-row>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="6"
      >
        <transaction-admin-chart />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <integration-admin-chart />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import WelcomeCard from '@/views/dashboard/WelcomeCard.vue'
import WorkflowAdminChart from './WorkflowAdminChart.vue'
import TransactionAdminChart from './TransactionAdminChart.vue'
import IntegrationAdminChart from './IntegrationAdminChart.vue'
import DashboardManageUserCard from './DashboardManageUserCard.vue'
import DashboardManageWorkflowCard from './DashboardManageWorkflowCard.vue'
import DashboardManageTransactionCard from './DashboardManageTransactionCard.vue'
import DashboardManageTokopediaCard from './DashboardManageTokopediaCard.vue'

export default {
  components: {
    WelcomeCard,
    WorkflowAdminChart,
    IntegrationAdminChart,
    DashboardManageUserCard,
    DashboardManageWorkflowCard,
    DashboardManageTransactionCard,
    DashboardManageTokopediaCard,
    TransactionAdminChart,
  },
  setup() {
  },
}
</script>
