<template>
  <v-card
    height="600px"
    class="pa-2"
  >
    <div class="d-flex justify-space-between align-center">
      <div>
        <v-card-title>
          Jumlah Token Seluruh Workspace
        </v-card-title>
      </div>
    </div>
    <v-card-text>
      <div class="d-flex flex-column">
        <chartjs-pie-chart
          v-if="!loadingDashboardWorkflow"
          :height="500"
          :data="chartData"
          :options="pieChart.options"
        />
        <template v-else>
          <v-overlay
            color="black"
            opacity="0.2"
            absolute
            :value="true"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-overlay>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import ChartjsPieChart from '@/views/dashboard/components/ChartjsPieChart.vue'
import useAutomationV2 from '@/composables/useAutomation'
import { computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    ChartjsPieChart,
    // DateRangePicker,
  },
  setup() {
    const { fetchWorkflowDashboardStatusAdmin, loadingDashboardWorkflow, dashboardWorkflowList } = useAutomationV2()

    onMounted(() => {
      fetchWorkflowDashboardStatusAdmin()
    })

    const pieChart = {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            fontSize: 12,
            boxWidth: 12,
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue)
              const currentValue = dataset.data[tooltipItem.index]
              const percentage = Math.floor(((currentValue / total) * 100) + 0.5)

              return `${currentValue} (${percentage}%)`
            },
          },
        },
      },
    }

    const chartData = computed(() => ({
      labels: dashboardWorkflowList.value.labels,
      datasets: [{
        data: dashboardWorkflowList.value.data,
        backgroundColor: ['#0088CC', '#16A085', '#25D366', '#FF6600', '#FF5080', '#FBBC05', '#0079BF'],
        hoverBackgroundColor: ['#0088CC', '#16A085', '#25D366', '#FF6600', '#FF5080', '#FBBC05', '#0079BF'],
      }],
    }))

    return {
      loadingDashboardWorkflow,
      dashboardWorkflowList,
      pieChart,
      chartData,
    }
  },
}
</script>
