<template>
  <v-card
    height="600px"
    class="pa-2"
  >
    <div class="d-flex justify-space-between align-center">
      <div>
        <v-card-title>
          Penggunaan Workflow Seluruh Workspace
        </v-card-title>
      </div>

      <div class="px-4">
        <span class="text-caption">Pilih Rentang Waktu</span>
        <v-select
          v-model="selectedTime"
          :items="timeOptions"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          class="mt-1"
          style="max-width: 180px;"
          @change="fetchWorkflowAdminDashboardUsage(selectedTime)"
        />
      </div>
    </div>
    <v-card-text>
      <div class="d-flex flex-column">
        <chartjs-line-chart
          v-if="!loadingDashboardWorkflowUsage"
          :height="500"
          :data="chartData"
          :options="lineChart.options"
          :plugins="plugins"
        />
        <template v-else>
          <v-overlay
            color="black"
            opacity="0.2"
            absolute
            :value="true"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-overlay>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import ChartjsLineChart from '@/views/dashboard/components/ChartjsLineChart.vue'
import { computed, onMounted, ref } from '@vue/composition-api'
import useAutomationV2 from '@/composables/useAutomation'
// import DateRangePicker from '@/components/inputs/DateRangePicker.vue'

export default {
  components: {
    ChartjsLineChart,
    // DateRangePicker,
  },
  setup() {
    const {
      fetchWorkflowAdminDashboardUsage, loadingDashboardWorkflowUsage, dashboardAdminWorkflowUsage,
    } = useAutomationV2()
    const selectedTime = ref(3)
    const timeOptions = [
      {
        text: '1 Bulan Terakhir',
        value: 1,
      },
      {
        text: '2 Bulan Terakhir',
        value: 2,
      },
      {
        text: '3 Bulan Terakhir',
        value: 3,
      },
      {
        text: '6 Bulan Terakhir',
        value: 6,
      },
      {
        text: '12 Bulan Terakhir',
        value: 12,
      },
    ]
    const chartColors = {
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }
    const plugins = [
      // to add spacing between legends and chart
      {
        beforeInit(chart) {
          /* eslint-disable func-names, no-param-reassign */
          chart.legend.afterFit = function () {
            this.height += 16
          }
          /* eslint-enable */
        },
      },
    ]
    const gridLineColor = 'rgba(195, 200, 216, 0.4)'

    const lineChart = {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: gridLineColor,
                zeroLineColor: gridLineColor,
              },
              ticks: {
                min: 0,
                fontColor: chartColors.labelColor,
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: gridLineColor,
                zeroLineColor: gridLineColor,
              },
              stacked: true,
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 20,
            boxWidth: 9,
          },
        },
        tooltips: {
          mode: 'label',
          intersect: false,
        },
      },
    }

    onMounted(() => {
      fetchWorkflowAdminDashboardUsage(selectedTime.value)
    })

    const chartData = computed(() => ({
      labels: dashboardAdminWorkflowUsage.value.labels,
      datasets: [
        {
          data: dashboardAdminWorkflowUsage.value.data,
          label: 'Penggunaan Workflow',
          borderColor: '#6285F6',
          lineTension: 0.3,
          pointStyle: 'circle',
          backgroundColor: 'rgba(163, 184, 250, 0.5)',
          pointBackgroundColor: '#6285F6',
          fill: true,
          pointRadius: 3,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: '#57679A',
          pointHoverBackgroundColor: '#6285F6',
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: '#57679A',
          stack: '1',
        },
      ],
    }))

    return {
      lineChart,
      plugins,
      timeOptions,
      selectedTime,
      chartData,
      fetchWorkflowAdminDashboardUsage,
      loadingDashboardWorkflowUsage,
      dashboardAdminWorkflowUsage,
    }
  },
}
</script>
