import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-2",attrs:{"height":"600px"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c(VCardTitle,[_vm._v(" Penggunaan Workflow Seluruh Workspace ")])],1),_c('div',{staticClass:"px-4"},[_c('span',{staticClass:"text-caption"},[_vm._v("Pilih Rentang Waktu")]),_c(VSelect,{staticClass:"mt-1",staticStyle:{"max-width":"180px"},attrs:{"items":_vm.timeOptions,"item-text":"text","item-value":"value","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.fetchWorkflowAdminDashboardUsage(_vm.selectedTime)}},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}})],1)]),_c(VCardText,[_c('div',{staticClass:"d-flex flex-column"},[(!_vm.loadingDashboardWorkflowUsage)?_c('chartjs-line-chart',{attrs:{"height":500,"data":_vm.chartData,"options":_vm.lineChart.options,"plugins":_vm.plugins}}):[_c(VOverlay,{attrs:{"color":"black","opacity":"0.2","absolute":"","value":true}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }